import React from "react"
import { Link } from "gatsby"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

// SEO
import SEO from "../components/SEO"

// Components
import Layout from "../components/Layout"

// Data
import content from "../pages/static-pages/edge.yaml"

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title={content.title}
          description={content.seo_description}
          image={content.seo_image}
        />

        <div className="kep-edge">
          {/* Feature */}
          <div
            className="feature-banner uk-section uk-background-cover uk-light"
            data-src={content.feature.image.url}
            uk-img=""
          >
            <div className="uk-container uk-margin-large-top uk-padding">
              <div className="uk-width-4-5 uk-width-3-5@s">
                <img
                  src="/images/edge/Kaden_Edge_Logo_CMYK_REV.png"
                  alt="Kaden Edge logo"
                  style={{ maxWidth: "291.86px" }}
                />
                <h1
                  className="uk-margin-top uk-text-global-family uk-h2 uk-text-bold"
                  dangerouslySetInnerHTML={{ __html: content.feature.title }}
                ></h1>
              </div>
            </div>
          </div>

          {/* Intro */}
          <div className="uk-section uk-section-default kep-edge-intro">
            <div className="uk-container uk-padding uk-padding-remove-top uk-padding-remove-bottom">
              <hr className="uk-divider-small uk-margin-medium" />
              <div className="uk-width-4-5@m uk-text-left">
                <h2 className="uk-margin">{content.title}</h2>
                <p className="uk-margin-remove-top">{content.description}</p>
              </div>
            </div>
          </div>

          {/* News panel */}
          <div className="uk-section uk-section-default kep-edge-newsSlider">
            <div className="uk-container uk-text-white">
              <div className="uk-grid uk-flex-middle uk-width-5-6@m uk-margin-auto">
                <div className="uk-card uk-card-body uk-width-1-2@m uk-text-middle ">
                  <h5 className="kep-edge-primary uk-margin-small-bottom line-height-small">
                    {content.news.subheading}
                  </h5>
                  <h2 className="kep-edge-default uk-margin-remove">
                    {content.news.title}
                  </h2>
                  <p className="light">{content.news.description}</p>
                  {content.news.url && (
                    <Link
                      to={content.news.url.url}
                      target="_blank"
                      className="uk-button kep-edge-default uk-border-pill uk-margin-bottom"
                    >
                      {content.news.url.title}
                    </Link>
                  )}
                </div>
                <div className="uk-card uk-card-body uk-width-1-2@m uk-flex-first">
                  <div className="uk-height-auto">
                    <div className="uk-background-cover uk-background-center-left">
                      <img
                        src={content.news.image.url}
                        alt={content.news.image.alt}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Partner Peerks */}
          <div className="uk-section kep-edge-partner">
            <div className="uk-container">
              <div>
                <h2 className="uk-h1 uk-margin-small-top uk-text-center">
                  {content.partner_perks.title}
                </h2>
                <div className="uk-grid uk-flex-center uk-child-width-1-2@s uk-child-width-1-3@l uk-margin-auto uk-margin-remove-left@s uk-text-center">
                  {content.partner_perks.perks.map((item, index) => (
                    <div
                      key={index}
                      className="uk-card uk-card-default uk-card-body uk-margin-top uk-padding-remove-left@s uk-padding-remove-right@s"
                    >
                      <div className="uk-cover-container">
                        <img
                          src={item.image}
                          alt={item.alt}
                          className="uk-cover"
                        />
                      </div>
                      <div className="uk-margin-medium-top">
                        <h2 className="uk-margin-remove-bottom">
                          {item.title}
                        </h2>
                        <p className="light">{item.description}</p>
                        <Link
                          to={item.url.url}
                          target="_blank"
                          className="uk-button uk-button-default uk-border-pill"
                        >
                          <span
                            className="uk-margin-small-right"
                            uk-icon={
                              "icon: " +
                              content.partner_perks.icon.type +
                              "; ratio: " +
                              content.partner_perks.icon.ratio +
                              ";"
                            }
                          ></span>
                          <span>{item.url.title}</span>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Split Panels Feedback */}
          <div className="uk-section uk-section-secondary ">
            <div className="uk-container uk-padding@m uk-padding-remove-right@m uk-padding-remove-left@m">
              <div className="uk-width-5-6@m uk-margin-auto uk-padding-remove@s">
                <div className="uk-grid uk-card uk-card-default uk-card-large uk-border-rounded uk-margin-remove-left">
                  <div className="uk-width-1-1 uk-width-2-5@m uk-flex uk-flex-middle uk-padding-small">
                    <div className="uk-padding">
                      <hr className="uk-divider-small uk-margin-medium" />
                      <h2>{content.feedback.title}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: content.feedback.description,
                        }}
                      />
                      <Link
                        to={content.feedback.button[0].link}
                        target="_blank"
                        className="uk-button uk-button-primary uk-border-pill uk-width uk-margin-small"
                      >
                        <span
                          className="uk-margin-small-right"
                          uk-icon={
                            "icon: " +
                            content.feedback.icon.type +
                            "; ratio: " +
                            content.feedback.icon.ratio +
                            ";"
                          }
                        ></span>
                        <span>{content.feedback.button[0].title}</span>
                      </Link>
                      <br />
                      <Link
                        to={content.feedback.button[1].link}
                        className="uk-button uk-button-default uk-border-pill uk-width uk-margin-small"
                      >
                        {content.feedback.button[1].title}
                      </Link>
                    </div>
                  </div>
                  <div className="uk-width-1-1 uk-width-3-5@m uk-card-media-right uk-flex-first uk-flex-last@m uk-cover-container uk-border-rounded-top-right uk-border-rounded-bottom-right uk-padding-remove-left">
                    <img
                      className="uk-cover"
                      src={content.feedback.image.url}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withAuthenticationRequired(Index)
